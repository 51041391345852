import React from 'react'
import { Slide, TypeOptions, toast, ToastContainer } from 'react-toastify'
import { Box, Typography } from '@mui/material'
import 'react-toastify/dist/ReactToastify.css'

interface IState {
  showToastify: (props: ToastProps) => void
}

export enum EToastifyType {
  success = 'success',
  error = 'error',
  warn = 'warning',
  info = 'info',
}

export interface ToastProps {
  type: TypeOptions
  message: string | React.ReactNode
  header?: string
  icon?: React.ReactNode
}

interface IProps {
  children?: React.ReactNode | React.ReactNode[]
}

const initialState: IState = {
  showToastify: () => null,
}

export const ToastifyContext = React.createContext<IState>(initialState)

export const ToastifyContextProvider = ({ children }: IProps) => {
  const showToastify = ({ message, type, header = '', icon }: ToastProps) => {
    toast(
      <Box ml={2}>
        <Typography fontWeight={350} mb={1}>
          {header}
        </Typography>
        <Box>{message}</Box>
      </Box>,
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        transition: Slide,
        type: type,
        icon: icon,
      }
    )
  }

  return (
    <ToastifyContext.Provider value={{ showToastify }}>
      <ToastContainer /> {children}
    </ToastifyContext.Provider>
  )
}
