import React, { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { Box, Button, DialogContentText, Stack, Typography } from '@mui/material'

import { EmailQueryKeys, useUnarchiveEmail } from '../../../service/hooks/email'
import { OrgBillQueryKeys } from '../../../service/hooks/orgBill'
import UnarchiveIcon from '../../../assets/images/icons/unarchive_icon.svg'
import CustomDialog from '../../../components/CustomDialog'
import { EToastifyType, ToastifyContext } from '../../../contexts/ToastifyContext'
import { ButtonPrimary } from '../../../utils/styles'

interface Props {
  orgGuid: string
  emailGuid: string
}

const UnarchiveBtn: React.FC<Props> = ({ orgGuid, emailGuid }) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const handleClose = () => setOpen(false)
  const { mutateAsync: unarchive } = useUnarchiveEmail(orgGuid, emailGuid)
  const queryClient = useQueryClient()
  const { showToastify } = useContext(ToastifyContext)

  return (
    <Box>
      <CustomDialog
        open={open}
        title={
          <>
            <Box display="flex" justifyContent="center">
              <img
                alt="archive"
                src={UnarchiveIcon}
                style={{ width: '48px', height: '48px', alignContent: 'center' }}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography fontSize={22} color="#2B388D" fontWeight={1000}>
                Unarchive Pre-bill
              </Typography>
            </Box>
          </>
        }
        onClose={handleClose}
      >
        <DialogContentText
          align="center"
          fontSize="18px"
          color="rgba(0, 0, 0, 0.87)"
          fontWeight={325}
        >
          Are you sure you want to unarchive this pre-bill?
          <Stack direction="row" display="flex" justifyContent="center" mt={2}>
            <Box mr={2}>
              <Button
                onClick={handleClose}
                sx={{
                  width: '236px',
                  height: '40px',
                  border: '1px solid #2B388D',
                }}
              >
                <Typography color="#2B388D" fontWeight={400}>
                  No
                </Typography>
              </Button>
            </Box>
            <Box>
              <ButtonPrimary
                onClick={() => {
                  unarchive({ orgGuid, emailGuid }).then(() => {
                    queryClient.invalidateQueries(EmailQueryKeys.GET_INBOX_EMAILS)
                    queryClient.invalidateQueries(OrgBillQueryKeys.GET_BILL_SUMMARY_LIST)
                  })
                  showToastify({
                    type: EToastifyType.info,
                    message: 'You have successfully unarchived a pre-bill.',
                    header: 'A pre-bill is unarchived',
                    icon: <img src={UnarchiveIcon} style={{ width: 40, height: 40 }} />,
                  })
                }}
                sx={{ width: '236px', height: '40px' }}
              >
                <Typography fontWeight={400}>Yes</Typography>
              </ButtonPrimary>
            </Box>
          </Stack>
        </DialogContentText>
      </CustomDialog>
      <Button onClick={() => setOpen(true)}>
        <img alt="archive" src={UnarchiveIcon} style={{ width: '26px', height: '26px' }} />
        <Typography ml={1} sx={{ fontWeight: 350, textDecoration: 'underline' }}>
          Unarchive
        </Typography>
      </Button>
    </Box>
  )
}

export default UnarchiveBtn
