import React, { useContext } from 'react'
import { Box, Button, Link, MenuItem, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import CustomDialog from '../CustomDialog'
import { EBillAction } from '../../service/enum'
import { useClientBillActionRejectUpdate } from '../../service/hooks/clientBill'
import { IBill, RejectReason, rejectReasonChoices } from '../../service/interface'
import { clientRoutesPath } from '../../routes/ClientRoutes'
import { orgRoutesPath } from '../../routes/OrgRoutes'
import { useParams } from 'react-router-dom'
import { EToastifyType, ToastifyContext } from '../../contexts/ToastifyContext'

export interface Props {
  bill: IBill
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

interface IRejectBillForm {
  reason: RejectReason
  otherDescription: string
}

const RejectBill: React.FC<Props> = ({ bill, open, onClose, onSuccess }) => {
  const billGuid = bill.guid
  const clientGuid = bill.client.guid

  const { register, handleSubmit, watch, control } = useForm<IRejectBillForm>()
  const watchReason = watch('reason')
  const {
    mutateAsync: requestUpdateClientBillActionReject,
    isLoading: isSubmitLoading,
  } = useClientBillActionRejectUpdate()
  const { showToastify } = useContext(ToastifyContext)

  const { userType } = useParams<{ userType: string }>()
  const billHref =
    userType === 'bporg'
      ? `${orgRoutesPath.clients}/${clientGuid}/${userType}/bill/${billGuid}`
      : `${clientRoutesPath.bill}/${billGuid}`

  const handleRejectBill = async ({ reason, otherDescription }: IRejectBillForm) => {
    await requestUpdateClientBillActionReject({
      clientGuid,
      billGuid,
      action: EBillAction.reject,
      reject_reason: reason,
      reject_other_description: otherDescription,
    })
      .then(() => {
        showToastify({
          type: EToastifyType.info,
          header: 'Bill rejected',
          message: (
            <Typography>
              You have rejected <Link href={billHref}>Invoice #{bill.ext_bill_id}</Link>
            </Typography>
          ),
        })
        onSuccess()
      })
      .catch(() => {
        showToastify({
          type: EToastifyType.error,
          header: 'Unable to reject bill.',
          message: (
            <Typography>
              You are unable to reject <Link href={billHref}>Invoice #{bill.ext_bill_id}</Link>
            </Typography>
          ),
        })
      })
    onClose()
  }

  return (
    <CustomDialog
      open={open}
      formOnSubmit={handleSubmit(handleRejectBill)}
      buttonCloseOnly
      onClose={onClose}
      title="Reject Bill"
      actions={
        <>
          <Button variant="outlined" onClick={onClose} disabled={isSubmitLoading}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" disabled={isSubmitLoading}>
            Submit
          </Button>
        </>
      }
    >
      <Box id="reason-select-section" sx={{ mb: 3 }}>
        <Controller
          as={
            <TextField select label="Choose a reason" size="small" fullWidth id="reason-field">
              {Object.entries(rejectReasonChoices).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          }
          name="reason"
          control={control}
          defaultValue=""
        />
      </Box>
      {watchReason === 'other' && (
        <Box id="other-reason-section">
          <TextField
            label="Please specify the reason"
            id="other-description-field"
            name="otherDescription"
            hiddenLabel
            multiline
            fullWidth
            rows={4}
            InputProps={{ sx: { fontSize: '14px', fontWeight: 325 } }}
            inputRef={register}
            required
          />
        </Box>
      )}
    </CustomDialog>
  )
}

export default RejectBill
