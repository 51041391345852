import React, { useContext } from 'react'
import { useQueryClient } from 'react-query'
import Button from '@mui/material/Button'
import UnarchiveIcon from '../../assets/images/icons/unarchive_icon.svg'
import { Box, Typography } from '@mui/material'
import ConfirmModalContent from '../../components/modals/ConfirmModalContent'
import CustomDialog from '../../components/CustomDialog'
import DeleteIcon from '@mui/icons-material/Delete'
import RemoveIcon from '../../assets/images/icons/remove_icon.svg'
import {
  OnboardingDocsQueryKeys,
  useDeleteClientOnboardingDocs,
} from '../../service/hooks/onboardingDocs'
import { EToastifyType, ToastifyContext } from '../../contexts/ToastifyContext'

interface Props {
  clientGuid: string
  docGuid: string
  onSuccess?: () => void
}

const DeleteDoc: React.FC<Props> = ({ clientGuid, docGuid, onSuccess }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const handleClose = () => setOpen(false)
  const { mutateAsync: requestDeleteOnboardingDocs } = useDeleteClientOnboardingDocs()
  const { showToastify } = useContext(ToastifyContext)
  const queryClient = useQueryClient()

  const handleConfirm = async () => {
    try {
      await requestDeleteOnboardingDocs({ clientGuid, documentGuid: docGuid }).then(() => {
        showToastify({
          type: EToastifyType.success,
          message: 'You have successfully removed a document.',
          icon: <img src={UnarchiveIcon} style={{ width: 40, height: 40 }} />,
        })
      })
      await queryClient.invalidateQueries(OnboardingDocsQueryKeys.GET_DOCS_LIST)
      if (onSuccess) onSuccess()
    } catch (e) {
      showToastify({
        type: EToastifyType.error,
        message: 'Failed to remove document.',
      })
    }
  }

  const handleDelete = () => {
    setOpen(true)
  }

  return (
    <Box mr={2}>
      <Button onClick={handleDelete}>
        <DeleteIcon sx={{ color: '#73839C' }} />
      </Button>
      <CustomDialog
        open={open}
        title={
          <>
            <Box display="flex" justifyContent="center">
              <img src={RemoveIcon} />
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography fontSize={22} color="#2B388D" fontWeight={1000}>
                Remove Document
              </Typography>
            </Box>
          </>
        }
        onClose={handleClose}
      >
        <ConfirmModalContent
          confirmMsg="Are you sure you want to proceed?"
          confirmText="Confirm"
          cancelText="Cancel"
          handleConfirm={handleConfirm}
          setOpen={setOpen}
        />
      </CustomDialog>
    </Box>
  )
}

export default DeleteDoc
