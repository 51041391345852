import React, { useContext, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Redirect, BrowserRouter as Router, Switch } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'

import theme from './theme'

import './assets/base.scss'
import './assets/generic.scss'

import 'react-phone-number-input/style.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import {
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar,
} from '@fortawesome/free-regular-svg-icons'
import {
  fas,
  faExclamation,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faCheck,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink,
} from '@fortawesome/free-solid-svg-icons'
import { ThemeOptionsProvider } from './contexts/ThemeOptions'
import GenericRoutes from './routes/GenericRoutes'
import OrgRoutes from './routes/OrgRoutes'
import ClientRoutes from './routes/ClientRoutes'
import { isBpOrgUser } from './utils/auth'
import { SidebarContextProvider } from './contexts/SidebarContext'
import TwoFASetup from './containers/general/Profile/TwoFASetup'
import { use2FACheck } from './service/hooks/auth'
import { isMobile } from 'react-device-detect'
import LoginMobileView from './containers/general/Login/LoginMobileView'
import { ModalContextProvider } from './contexts/ModalContext'
import { BillParamsContextProvider } from './contexts/BillParamsContext'
import { AdvancedSearchContextProvider } from './contexts/AdvancedSearchContext'
import { AuditHistoryContextProvider } from './contexts/AuditHistoryContext'
import { ParReportParamsContextProvider } from './contexts/ParReportParamsContext'
import PrivateRoute from './components/route/PrivateRoute'
import { CommitmentParamsContextProvider } from './contexts/CommitmentParamsContext'
import { EToastifyType, ToastifyContext, ToastifyContextProvider } from './contexts/ToastifyContext'

library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
)
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
)
library.add(
  fas,
  faExclamation,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faCheck,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
})

const App: React.FC = () => {
  const isOrgUser = isBpOrgUser()
  const isAuthenticated = isOrgUser !== null
  const shouldEnable2FA = use2FACheck(isAuthenticated)
  const { showToastify } = useContext(ToastifyContext)

  const [is2FASetupOpen, setIs2FASetupOpen] = useState(false)

  const downloadAppDisplay =
    window.location.pathname.includes('/create-account') ||
    window.location.pathname.includes('/download-app') ||
    window.location.pathname.includes('/reset-password')

  useEffect(() => {
    if (isMobile && !downloadAppDisplay) return

    if (shouldEnable2FA) {
      showToastify({
        type: EToastifyType.warn,
        message:
          'Your organization requires 2-factor authentication. Please set it up to continue.',
      })

      setIs2FASetupOpen(true)
    }
  }, [downloadAppDisplay, shouldEnable2FA, showToastify])

  if (isMobile && !downloadAppDisplay) return <LoginMobileView />

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastifyContextProvider>
          <Router>
            <ModalContextProvider>
              <SidebarContextProvider>
                <ThemeOptionsProvider>
                  <BillParamsContextProvider>
                    <CommitmentParamsContextProvider>
                      <AdvancedSearchContextProvider>
                        <AuditHistoryContextProvider>
                          <ParReportParamsContextProvider>
                            <TwoFASetup
                              open={is2FASetupOpen}
                              onClose={() => setIs2FASetupOpen(false)}
                            />
                            <Switch>
                              {GenericRoutes}
                              {isAuthenticated && isOrgUser && OrgRoutes}
                              {isAuthenticated && !isOrgUser && ClientRoutes}
                              {/* fallback route */}
                              <PrivateRoute>
                                <Redirect to="/login" />
                              </PrivateRoute>
                            </Switch>
                          </ParReportParamsContextProvider>
                        </AuditHistoryContextProvider>
                      </AdvancedSearchContextProvider>
                    </CommitmentParamsContextProvider>
                  </BillParamsContextProvider>
                </ThemeOptionsProvider>
              </SidebarContextProvider>
            </ModalContextProvider>
          </Router>
        </ToastifyContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
