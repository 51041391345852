import React from 'react'
import { EToastifyType, ToastifyContext } from '../../contexts/ToastifyContext'
import { useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

export const QBOConnectCompleteRedirectProperty = 'QBOConnectCompleteRedirect'

export const ConnectionCompleteRedirect: React.FC<{
  toastType: EToastifyType
  toastMessage: string
}> = ({ toastType, toastMessage }: { toastType: EToastifyType; toastMessage: string }) => {
  const { showToastify } = useContext(ToastifyContext)
  useEffect(() => {
    showToastify({
      type: toastType,
      message: toastMessage,
    })
  }, [showToastify, toastMessage, toastType])
  const redirect = window.localStorage.getItem(QBOConnectCompleteRedirectProperty) || '/dashboard'
  window.localStorage.removeItem(QBOConnectCompleteRedirectProperty)
  return <Redirect to={redirect} />
}
